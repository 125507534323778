import {
  Bundleable,
  BundleableBase,
  MetadataKey,
  MetadataType,
} from "./metadata/types"
import { MetadataInfo, NamespaceInfo } from "./platform/platform"

export type {
  Bundleable,
  BundleableBase,
  MetadataType,
  MetadataKey,
  MetadataInfo,
  NamespaceInfo,
}

export { getKey, METADATA } from "./metadata/metadata"
